import React from "react"
import loadable from "@loadable/component"
import { Helmet } from "react-helmet"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))
// const ChatComponent = loadable(() =>
//   import("../../components/Chat Iframe IE11")
// )

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <div>
      <Helmet>
        <script
          defer
          data-domain="bots.convaise.com"
          src="https://plausible.io/js/plausible.js"
        ></script>
      </Helmet>
      <ChatComponent
        name="eOpt Solutions"
        // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
        botId="ddxmyif4l1c3stmr"
        avatar="false"
        domain="https://europe.directline.botframework.com/v3/directline"
        webSocket={true}
        styleOptions={{
          primaryFont: "'Noto Sans', 'sans-serif'",
          bubbleFromUserTextColor: "white",
          bubbleTextColor: "#293754",
          bubbleFromUserBackground: "#394d75",
          botAvatarBackgroundColor: "#fff",
        }}
        // disconnectWhenClosed
        styleCustomizations={{
          "--button-blue": "#006AB3",
          "--button-blue-light": "#0084e0",
          "--font-size-normal": "14px",
          "--button-font-size": "14px",
        }}
        // css="https://static.convaise.com/webchat/v4/convaise-assistant-min_v4-1.css"
      />
    </div>
  )
}

export default Chat
